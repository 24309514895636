import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient'; // Import your Supabase client configuration
import { IoCloseCircleSharp } from 'react-icons/io5';

const AdminSettings = () => {
  const [settings, setSettings] = useState({
    coolDownTime: 0,
    tappingGuru: 0,
    swapEnabled: false, // Toggle for swap feature
    swapMessage: "" // New field for the swap announcement message
  });
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    const { data, error } = await supabase
      .from('settings') // Replace 'settings' with your actual table name
      .select('*')
      .eq('id', '1q01CYx0LFmgLR4wiUxX'); // Replace with your actual row ID or adjust if necessary

    if (error) {
      console.error("Error fetching settings: ", error);
    } else if (data && data.length > 0) {
      setSettings({
        ...data[0],
        swapEnabled: data[0].swapEnabled ?? false,
        swapMessage: data[0].swapMessage ?? "Announcement: The swap function is temporarily unavailable. Withdrawals will be activated after the end of the OKPI token presale on 22.12.2024. Tap to acquire more OKPI coins and participate in the presale with a larger amount!" // Default message
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSettings({ ...settings, [name]: name === 'swapEnabled' ? e.target.checked : value });
  };

  const handleUpdateSettings = async () => {
    const { error } = await supabase
      .from('settings') // Replace with your actual table name
      .update(settings)
      .eq('id', '1q01CYx0LFmgLR4wiUxX'); // Replace with your actual row ID or adjust if necessary

    if (error) {
      console.error("Error updating settings: ", error);
    } else {
      fetchSettings();
      setShowSuccessModal(true); // Show success modal
    }
  };

  const closeModal = () => {
    setShowSuccessModal(false);
  };

  return (
    <div id='refer' className="w-full flex flex-col space-y-4 h-[100vh] scroller pt-4 overflow-y-auto pb-[150px]">
      <h1 className="text-[20px] font-semibold mb-1">Set Default Values</h1>

      <div className="flex w-full flex-wrap gap-3">
        <div className="flex items-center">
          <input
            type="checkbox"
            name="swapEnabled"
            checked={settings.swapEnabled}
            onChange={handleInputChange}
            className="mr-2"
          />
          <label htmlFor="swapEnabled">Enable Swap Feature</label>
        </div>

        <div className="w-full">
          <label htmlFor="swapMessage" className="block font-semibold mb-1">Swap Disabled Message</label>
          <textarea
  name="swapMessage"
  value={settings.swapMessage}
  onChange={handleInputChange}
  className="w-full h-24 p-2 border rounded text-black" // Added text-black for black text
  placeholder="Enter the message to display when the swap feature is disabled"
/>

        </div>

        <button onClick={handleUpdateSettings} className="bg-green-500 font-semibold text-[15px] rounded-[6px] w-[50%] sm:w-[200px] h-fit px-4 py-3 text-[#fff]">
          Update Settings
        </button>
      </div>

      {/* Success Modal */}
      {showSuccessModal && (
        <div className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
          
          <div className="modal-container bg-[#595D65] w-11/12 md:max-w-md mx-auto rounded-[10px] shadow-lg z-50 overflow-y-auto">
            <div className="modal-content py-4 text-left px-6">
              <div className="flex justify-end items-center pb-3">
                <div className="modal-close cursor-pointer z-50" onClick={closeModal}>
                  <IoCloseCircleSharp size={32} className='text-secondary' />
                </div>
              </div>
              <div className="flex justify-center items-center">
                <p>Settings have been updated successfully.</p>
              </div>
              <div className="flex justify-center pt-2">
                <button className="modal-close bg-blue-500 text-white p-2 px-6 rounded" onClick={closeModal}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminSettings;
