import React, { useState, useEffect } from 'react';
import YouTube from 'react-youtube';
import { supabase } from '../supabaseClient';
import { useUser } from "../context/userContext";
import { IoClose } from 'react-icons/io5';
import './YouTubeTasks.css';

const YouTubeTasks = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [videoEnded, setVideoEnded] = useState(false);
    const { id: userId, youtubeTasks, setBalance, setUserYoutubeTasks } = useUser();
    const [activeTask, setActiveTask] = useState(null);
    const [collectMessage, setCollectMessage] = useState('');
    const [completedTaskIds, setCompletedTaskIds] = useState([]); // Stores all completed task IDs

    useEffect(() => {
        loadUncompletedTasks();
    }, []);

    // Load uncompleted tasks from Supabase
    const loadUncompletedTasks = async () => {
        try {
            const { data, error } = await supabase
                .from('telegramUsers')
                .select('youtubeTasks')
                .eq('userId', userId)
                .single();

            if (error) throw error;

            const completedTaskIds = data?.youtubeTasks?.map(task => task.id) || [];
            setCompletedTaskIds(completedTaskIds); // Store completed task IDs in state

            const uncompletedTasks = youtubeTasks.filter(task => !completedTaskIds.includes(task.id));
            setUserYoutubeTasks(uncompletedTasks);
        } catch (error) {
            console.error("Error loading tasks: ", error);
        }
    };

    // Open modal and set active task
    const openVideoModal = (task) => {
        setActiveTask(task);
        setModalOpen(true);
        setVideoEnded(false); // Reset the videoEnded flag each time a new task is opened
        setCollectMessage(''); // Clear any previous collect message
    };

    // Close modal and reset states
    const closeModal = () => {
        setModalOpen(false);
        setActiveTask(null);
        setVideoEnded(false); // Ensure video status is reset on modal close
    };

    // Handle video end event
    const handleVideoEnd = () => {
        setVideoEnded(true);
        setCollectMessage(`You've finished the video! Click to collect ${activeTask.bonus} points.`);
    };

    const collectPoints = async () => {
        if (videoEnded && activeTask && !completedTaskIds.includes(activeTask.id)) { // Ensure collectPoints only runs if the video has ended and task is not already completed
            try {
                const { data, error: fetchError } = await supabase
                    .from('telegramUsers')
                    .select('balance, youtubeTasks')
                    .eq('userId', userId)
                    .single();

                if (fetchError) throw fetchError;

                const currentBalance = data.balance || 0;
                const completedTasks = data.youtubeTasks || [];

                const updatedTasks = [...completedTasks, { id: activeTask.id, completed: true }];

                // Update balance and youtubeTasks in the database
                const { error: updateError } = await supabase
                    .from('telegramUsers')
                    .update({
                        balance: currentBalance + activeTask.bonus,
                        youtubeTasks: updatedTasks // Save the updated unique list
                    })
                    .eq('userId', userId);

                if (updateError) throw updateError;

                // Update the local state
                setBalance(prevBalance => prevBalance + activeTask.bonus);
                setUserYoutubeTasks(prevTasks => prevTasks.filter(task => task.id !== activeTask.id));
                setCompletedTaskIds([...completedTaskIds, activeTask.id]); // Add the completed task ID to the list
                setCollectMessage(`You've collected ${activeTask.bonus} points!`);
                
                // Close modal after a delay to show the message
                setTimeout(closeModal, 3000);
            } catch (error) {
                console.error("Error updating task status to completed: ", error);
            }
        }
    };
    
    const videoOptions = {
        height: '315',
        width: '100%',
        playerVars: {
            autoplay: 1,
        },
    };

    return (
        <>
            <div className="youtube-tasks-grid">
                {youtubeTasks.filter(task => !completedTaskIds.includes(task.id)).map(task => ( // Filter out completed tasks
                    <div key={task.id} onClick={() => openVideoModal(task)} className="task-card">
                        <img src={task.thumb} alt="task thumbnail" className="task-thumbnail" />
                        <h3 className="task-title">{task.title}</h3>
                        <p className="task-bonus">+{task.bonus} points</p>
                        <button className="task-button">Watch & Earn</button>
                    </div>
                ))}
            </div>

            {modalOpen && activeTask && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <button onClick={closeModal} className="close-button">
                            <IoClose size={24} />
                        </button>
                        <h2 className="modal-title">{activeTask.title}</h2>
                        <p className="modal-description">{activeTask.description}</p>
                        <div className="video-wrapper">
                            <YouTube
                                videoId={extractVideoId(activeTask.link)}
                                opts={videoOptions}
                                onEnd={handleVideoEnd} // Trigger collect eligibility when video ends
                            />
                        </div>
                        {completedTaskIds.includes(activeTask.id) ? (
                            <p className="already-completed-message">You've already completed this task.</p>
                        ) : videoEnded ? (
                            <button onClick={collectPoints} className="collect-points-button">
                                {collectMessage || 'Collect Points'}
                            </button>
                        ) : (
                            <p className="waiting-message">Watch the full video to unlock your points!</p>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

// Utility function to extract YouTube video ID from URL
function extractVideoId(url) {
    const match = url.match(/[?&]v=([^&]+)/);
    return match ? match[1] : '';
}

export default YouTubeTasks;
